import {BadgeCheckIcon} from '@heroicons/react/solid'
import {useAtomValue} from 'jotai/utils'
import {format} from 'date-fns'
import {chosenDateAtom} from 'atoms'
import {sk} from "date-fns/locale";

const map = require(`${process.env.REACT_APP_MAP_PATH}`)

export default function Confirmation() {
    const chosenDate = useAtomValue(chosenDateAtom)

    function getDateFormatted(date, dateFormat) {
        return date && format(Date.parse(date.replace(' ', 'T')), dateFormat, {locale: sk})
    }

    return (
        <>
            {chosenDate &&
                <div className='flex w-full sm:justify-between gap-2 mt-5 '>
                    <div
                        className='bg-white rounded-lg w-full sm:px-10 sm:py-6 p-5 flex flex-col gap-3 text-sm text-gray-500'>
                        <div className='flex items-center justify-between'>
                            <h1 className='text-2xl font-bold leading-9 text-zinc-900'>Ďakujeme !</h1>
                            <BadgeCheckIcon className="h-12 w-12 text-green-400" aria-hidden="true"/>
                        </div>
                        <p>Dátum <span
                            className='font-bold text-zinc-900'>{getDateFormatted(chosenDate, 'dd.MM.yyyy HH:mm')}</span> bol
                            úspešne vybraný.</p>
                        <p>Vozidlo si vyzdvihnite vo vybranom termíne v United Mobility Centre.</p>
                        <a href={process.env.REACT_APP_GEO_HREF}
                           target="_blank"
                           rel="noreferrer"
                           className='flex items-center justify-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-primary-500 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:text-sm'>
                            Nájdete nás tu
                        </a>
                        <a href={process.env.REACT_APP_GEO_HREF}
                           target="_blank"
                           className='border-2 border-primary-500 rounded'
                           rel="noreferrer">
                            <img src={map} alt={`${process.env.REACT_APP_BRAND} Map`}/>
                        </a>
                    </div>
                </div>
            }
        </>
    )
}